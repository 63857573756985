.addClient-container {
    background-color: white;
    width: 100%;
    /* margin: 5rem; */
    border-radius: 0.25rem; 
    padding: 1rem;
    align-self: center;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); Set your desired shadow properties */
  }
  
  

  .change-profile {
    display: flex; 
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1024px) {
    .addClient-container {
      width: 80%;
      margin: 1rem;
      margin-top: 2rem;
    }
  }

  @media (max-width: 425px) {
    .change-profile {
      display: block; 
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .addClient-container {
      width: 90%;
      margin: 0rem;
      margin-top: 2rem;
    }
  }
  /* addClient-container */