.slide-container{
    /* display: flex;
    flex-direction: column;
    width: 65%;
    overflow-y: auto; 
    margin-top: 3rem;
    margin-bottom: 6rem;
    border-style: solid;
    border-width: 2px;
    border-color: darkgray;
    border-radius: 5px; */

    /* display: flex;
    flex-direction: column; */
    width: 100%;
    overflow-y: auto; 
    margin-top: 3rem;
    margin-bottom: 3rem;
    /* border-style: solid;
    border-width: 2px;
    border-color: darkgray;
    border-radius: 5px; */
}

.slide-modal-container{
    display: flex;
    flex-direction: column;
    width: 90%;
    align-self: center;
}

.property-features {
    display:flex;
    flex-direction:"row";
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    width: 50%;
}

.headingTxt {
    font-family: 'Montserrat';
    font-size: 2rem;
    font-weight: 500;
    line-height: normal;
    color: black;
}

.property-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .image-style{
    width: 100%;
    height: 15rem; 
    max-width: 100%; 
    max-height: 16rem; 
    object-fit: cover; 
  }

  .slider-container{
    /* display: flex;
    align-items: center;
    justify-content: flex-start; */
    background:linear-gradient(white,transparent);
    /* background-color:lightgray;  */
    background-color:#ffff; 
    max-width: 98%;
    align-self: center;
  }

  .property-price-slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: 'flex-start';
    /* max-width: 40%; */
    min-width: 30%;
    padding: 0.5rem;
    background:linear-gradient(white,transparent);
    /* background-color:lightgray;  */
    background-color: #f2f2f2; 
  }

  .image-logo {
    width: 3rem; 
    height: 3rem;
  }

  .date-style {
    display:'flex';
    flex-direction: row;
    align-items:'center';
    justify-content:'flex-start';
  }

  .newMenu { 
    padding-right:'1rem'; 
    margin-right:"1rem"; 
    border-right:'1px solid #bbb' 
  }

  .footer_section{
    display: flex; 
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
  }

  @media (max-width: 425px) {
    .property-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
      }
      
      .slider-container{
        max-width: 96%;
      }

      .slider-container{
        display: none;
      }

      .newMenu{
        display: none;
      }

      .footer_section{
        display: flex; 
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }
  }

  @media (max-width: 768px) {
    .property-features {
        width: 100%;
    }

    .property-price-slider {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: 'flex-start';
      /* max-width: 40%; */
      /* min-width: 60%; */
      padding: 0.5rem;
      background:linear-gradient(white,transparent);
      /* background-color:lightgray;  */
      background-color: #f2f2f2;  
    }

    .image-logo {
      width: 2rem; 
      height: 2rem;
    }

    
  }
  
  @media (max-width: 1024px) {
    .property-features {
        width: 70%;
    }
    .slider-container{
        max-width: 97%;
    }
  }

  @media (max-width: 1440)   {
    .slider-container{
      max-width: 96%;
    }
  }

  @media (min-width: 2560px) {
    .image-style{
        width: 100%;
        height: 25rem; 
        max-width: 100%; 
        object-fit: fill; 
        max-height: 30rem; 
        background-color: red;
    }
   
  }

  
  
  