.user-table-style{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}


  

  
  .agentassign {
    /* width: 100%; */
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
 
  