/* Global styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0; /* Example background color */
}

/* Navbar logo */
.navbar-logo {
  width: 200px;
  height: 70px;
  /* Adjust dimensions as needed */
}

/* Styles for CircularProgressbar */
.CircularProgressbar {
  width: 70%; /* Adjust width as needed */
  padding: 5px;
  margin: 10px auto;
  text-align: center;
  vertical-align: middle; /* Fix centering issue */
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #000;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #000;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/* Optional: Inverted styles for CircularProgressbar */
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #000;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

/* Animation for active table row */
@keyframes highlight-animation {
  0% {
    background-color: rgb(206, 206, 177); /* Highlight color */
  }
  100% {
    background-color: white; /* Final color */
  }
}

/* Base style for all table rows */
th,
td {
  padding: 15px; /* Adjust padding for spacing */
  height: 60px; /* Increase height as needed */
}

/* Apply animation to active row */
.active-row {
  animation: highlight-animation 1s ease-in-out infinite alternate;
}

/* Optional: Override other table row styles */
.table tbody tr {
  background-color: white; /* Or whatever background color you want */
}

/* Card styling */
.card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-header {
  background-color: black;
  color: white;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* Container styling */
.screencontainer {
  padding: 5px;
  margin: 5px auto;
  text-align: center;
}

/* Table styling */
.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table th,
.table td {
  padding: 15px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
  border-bottom: 1px solid #ddd;
}

.table td {
  border-bottom: 1px solid #ddd;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

/* Highlight animation for active row */
@keyframes highlight-animation {
  0% {
      background-color: rgb(206, 206, 177); /* Highlight color */
  }
  100% {
      background-color: white; /* Final color */
  }
}

/* Optional: Override other table row styles */
.table tbody tr {
  background-color: white; /* Or whatever background color you want */
}
