
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color:black; 
}

.content {
  display: flex;
  width: 80%; 
  max-width: 1200px; 
  border-radius: 10px; 
}

.left {
  padding: 20px;
  width: 40%;
}

.right {
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.heading-txt{
  font-family: 'Montserrat';
  font-size: 4.25rem;
  font-weight: 500;
  color: white;
}

.right img {
  max-width: 100%; 
  border-top-right-radius: 10px; 
  border-bottom-right-radius: 10px; 
}

.container2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* height: 35vh; */
  background-color:white; 
  margin-top: 4rem;
  padding: 1rem;
}

.content2 {
  /* display: flex; */
  /* width: 85%;  */
  width: 64%; 
  /* width: 62%;  */
  max-width: 1200%; 
  border-radius: 10px; 
}

.title_style{
  /* margin-left: 13%; */
  width:100%
}

.pieChart_Container { 
  /* border-right: 1px solid lightgray;  */

}

.pieContainer { 
  background-color: white; 
  width: 50%; 
  margin: 5rem 
}

@media (max-width: 425px) {
  .heading-txt{
    font-size: 1rem;
  }
  .title_style{
    /* margin-left: 5%; */
  width:100%

  }
  .pieContainer { 
    background-color: white; 
    width: 90%; 
    margin: 5rem 
  }
  
  .pieChart_Container { 
    border-bottom: 1px solid lightgray; 
    border-right: 0px solid lightgray; 
    bottom: 2rem;
    padding-bottom: 2rem;
  }
}

@media (max-width: 768) {
  .heading-txt{
    font-size: 1.75rem;
  }
  
  .title_style{
    /* margin-left: 10%; */
  width:100%

  }
  
  .pieChart_Container { 
    border-bottom: 1px solid lightgray; 
    border-right: 0px solid lightgray; 
    bottom: 2rem;
    padding-bottom: 2rem;
  }

  .pieContainer { 
    width: 80%; 
    margin: 5rem 
  }
}

@media (max-width: 1024px) {
  .heading-txt{
    font-size: 3.25rem;
  }

  .content2 {
    width: 95%; 
  }

  /* .pieContainer { 
    background-color: yellow; 
    width: 60%; 
    margin: 5rem 
  } */
}

@media (max-width: 1440px) {
  .heading-txt{
    font-size: 3.25rem;
  }

  /* .pieContainer { 
    background-color: #ffff; 
    width: 80%; 
    margin: 5rem 
  } */

  .content2 {
    width: 95%; 
  }

  /* .pieContainer { 
    width: 50%; 
    margin: 5rem 
  } */
}
