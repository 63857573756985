.login-front-page{
    display: flex;
    flex: 1;
    align-items: center;
    background-color: aquamarine;
}

.image_style {
    width: 32%; 
    height: 21rem;
}

@media (max-width: 425px) {
    .image_style {
        width: 50%; 
        height: 10rem;
    }
}

@media (max-width: 768px) {
    .image_style {
        width: 100%; 
        height: 20rem;
    }
    
}