.checkbox-container {
    position: absolute;
    top: 334px;
    left: 210px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.checkbox {
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 5px;
}