/* Base Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}
.signature-canvas {
    border: 1px solid black;
    border-radius: 5px;
}

.containerf {
    width: 80%;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.header img {
    max-width: 150px;
    margin-bottom: 10px;
}

.header h1 {
    margin: 0;
    font-size: 24px;
    color: #333;
}

/* Section Title */
.section-title {
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0 10px;
    border-bottom: 2px solid #333;
    padding-bottom: 5px;
    color: #333;
}

/* Form Layout */
.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}

.form-group {
    flex: 1;
    min-width: 220px;
    box-sizing: border-box;
}

.form-group.full-width {
    flex: 1 100%;
}

.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 15px;
}

input[type="radio"],
input[type="checkbox"] {
    margin-right: 10px;
}

.checkbox-group {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
}

button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 20px;
}

button:hover {
    background-color: #0056b3;
}

/* Focus States */
input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="date"]:focus,
select:focus {
    border-color: #007bff;
    outline: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
    }
    .form-group {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .header img {
        max-width: 120px;
    }
}
